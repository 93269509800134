<template>
  <b-modal
    centered
    size="md"
    no-close-on-backdrop
    hide-header-close
    v-model="isModalOpen"
  >
    <template #modal-title>Cancel Confirmation </template>
    <template #default>
      <div class="modal-custom-content">
        <p>
          Your changes will be lost if you don’t save them. Are you sure you
          want to leave this page?
        </p>
      </div>
    </template>
    <template #modal-footer>
      <div class="modal-btn text-right">
        <button class="btn btn-secondary" @click.prevent="$emit('no')">
          No
        </button>
        <button class="btn btn-primary" @click.prevent="$emit('yes')">
          Yes, leave this page
        </button>
      </div>
    </template>
  </b-modal>
</template>
<script>
export default {
  name: 'ModalCancelConfirm',
  props: {
    isOpen: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    isModalOpen: {
      get() {
        return this.isOpen
      },
      set() {
        this.$emit('update:isOpen', false)
      }
    }
  }
}
</script>
