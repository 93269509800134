<template>
  <main class="subscriptionEdit">
    <b-breadcrumb :items="breadcrumb" />
    <div class="container" :class="{ 'mx-auto': !sidebarShow }">
      <section class="section">
        <div
          class="subscriptionEdit-form"
          v-if="formShow"
          :class="{ 'mx-auto': !sidebarShow }"
        >
          <div class="section-title subscriptionEdit-title">
            Subscription {{ subscriptionType }}
          </div>
          <form>
            <div class="form-group row">
              <div class="col col-form-label subscriptionEdit-label">
                Company
              </div>
              <div class="col-md">
                <input
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': errorMsg.companyName }"
                  v-model.trim.lazy="companyName"
                  required
                />
                <div class="invalid-feedback" v-show="errorMsg.companyName">
                  {{ errorMsg.companyName }}
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col col-form-label subscriptionEdit-label">
                Validation Period
              </div>
              <div class="col-md">
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <div class="validPeriod-label">Start date</div>
                    <div class="validPeriod-content">
                      <b-form-datepicker
                        hide-header
                        locale="en"
                        size="sm"
                        placeholder="Choose a date"
                        v-model.trim.lazy="validFrom"
                        :date-format-options="dateFormat"
                        :state="errorMsg.validFrom ? false : undefined"
                      ></b-form-datepicker>
                      <div class="invalid-feedback" v-show="errorMsg.validFrom">
                        {{ errorMsg.validFrom }}
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-md-6">
                    <div class="validPeriod-label">
                      End date
                    </div>
                    <div class="validPeriod-content">
                      <b-form-datepicker
                        hide-header
                        locale="en"
                        size="sm"
                        v-model.trim.lazy="validUntil"
                        :min="validFrom"
                        :date-format-options="dateFormat"
                        :disabled="validUntilStatus"
                        :placeholder="
                          validUntilStatus ? 'untill' : 'Choose a date'
                        "
                        :state="errorMsg.validUntil ? false : undefined"
                      ></b-form-datepicker>
                      <b-form-checkbox v-model="validUntilStatus"
                        >untill
                      </b-form-checkbox>
                      <div
                        class="invalid-feedback"
                        v-show="errorMsg.validUntil"
                      >
                        {{ errorMsg.validUntil }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col col-form-label subscriptionEdit-label">
                Max. Number of Active tasks
              </div>
              <div class="col-md">
                <input
                  type="number"
                  class="form-control"
                  :class="{ 'is-invalid': errorMsg.maxNumOfActiveTasks }"
                  v-model.trim.number.lazy="maxNumOfActiveTasks"
                  min="1"
                />
                <div
                  class="invalid-feedback"
                  v-show="errorMsg.maxNumOfActiveTasks"
                >
                  {{ errorMsg.maxNumOfActiveTasks }}
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col col-form-label subscriptionEdit-label">
                FiveStones Contact Email Address
              </div>
              <div class="col-md">
                <MultiSelect
                  v-model="fsContacts"
                  :options="fivestonesStaff ? fivestonesStaff : []"
                  :multiple="true"
                  :close-on-select="true"
                  :clear-on-select="false"
                  :preserve-search="true"
                  placeholder="select some one"
                  label="fullName"
                  :track-by="fivestonesStaff ? 'emailAddress' : ''"
                >
                  <template slot="tag" slot-scope=""></template>
                  <template slot="selection" slot-scope="{ values, isOpen }"
                    ><span
                      class="multiselect__single"
                      v-if="values.length && !isOpen"
                      >{{ values.length }} user selected</span
                    ></template
                  >
                </MultiSelect>
                <MultiSelectTag :tagList.sync="fsContacts" />
                <div class="invalid-feedback" v-show="errorMsg.fsContacts">
                  {{ errorMsg.fsContacts }}
                </div>
              </div>
            </div>
            <div class="form-group form-btn">
              <button
                class="btn btn-secondary btn-cancel"
                @click.prevent="modalShowHide"
              >
                Cancel
              </button>
              <button
                class="btn btn-primary btn-save"
                @click.prevent="SubmitHandler"
              >
                {{ subscriptionType === 'New' ? 'Create' : 'Save' }}
              </button>
            </div>
            <div class="alert alert-danger" role="alert" v-show="errorMsg.all">
              {{ errorMsg.all }}
            </div>
          </form>
        </div>
      </section>
    </div>
    <ModalCancelConfirm
      :isOpen.sync="cancelModalShow"
      @yes="goBackHandler"
      @no="modalShowHide"
    />
  </main>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import MultiSelectTag from '../components/MultiSelectTag.vue'
import ModalCancelConfirm from '../components/ModalCancelConfirm.vue'

export default {
  name: 'SubscriptionSetup',
  components: {
    MultiSelectTag,
    ModalCancelConfirm
  },
  data() {
    return {
      subscriptionType: '',
      formShow: false,
      dateFormat: {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      },
      errorMsg: {
        companyName: '',
        maxNumOfActiveTasks: '',
        validFrom: '',
        validUntil: '',
        fsContacts: '',
        all: ''
      },
      cancelModalShow: false
    }
  },
  async mounted() {
    const id = this.$route.params.id
    id ? (this.subscriptionType = 'Edit') : (this.subscriptionType = 'New')
    let status = await this['subscription/pageSetupCreated']({ id })
    if (status === 'success') {
      this.formShow = true
    } else {
      console.log('subscriptionSetup created: ' + status)
      this.$router.push('/pages/page403')
    }
  },
  computed: {
    ...mapState({
      sidebarShow: state => state.sidebarShow,
      subscriptionEdit: state => state.subscription.edit,
      fivestonesStaff: state => state.subscription.fivestonesStaff
    }),
    breadcrumb() {
      return [
        {
          text: 'Subscription',
          to: '/platform/subscription'
        },
        {
          text: this.subscriptionType,
          active: true
        }
      ]
    },
    companyName: {
      get() {
        return this.subscriptionEdit.companyName
      },
      set(value) {
        this.validationCompanyName(value)
        this['subscription/setEditSetup']({
          type: 'companyName',
          value
        })
      }
    },
    validFrom: {
      get() {
        return this.subscriptionEdit.validFrom
          ? this.subscriptionEdit.validFrom
          : ''
      },
      set(value) {
        this.validationValidFrom(value)
        this['subscription/setEditSetup']({
          type: 'validFrom',
          value: this.dateToString(value)
        })
      }
    },
    validUntil: {
      get() {
        return this.subscriptionEdit.validUntil
      },
      set(value) {
        this.validationValidUntil(value)
        value = value ? this.dateToString(value) : value
        this['subscription/setEditSetup']({
          type: 'validUntil',
          value
        })
      }
    },
    validUntilStatus: {
      get() {
        return this.validUntil === null ? true : false
      },
      set(val) {
        this.validUntil = val ? null : ''
        this.validationValidUntil(this.validUntil)
      }
    },
    maxNumOfActiveTasks: {
      get() {
        return this.subscriptionEdit.maxNumOfActiveTasks
      },
      set(value) {
        this.validationMaxNumOfActiveTasks(value)
        this['subscription/setEditSetup']({
          type: 'maxNumOfActiveTasks',
          value
        })
      }
    },
    fsContacts: {
      get() {
        return this.subscriptionEdit.fsContacts
      },
      set(value) {
        this.validationFsContacts(value)
        this['subscription/setEditSetup']({
          type: 'fsContacts',
          value
        })
      }
    }
  },
  methods: {
    ...mapActions([
      'subscription/pageSetupCreated',
      'subscription/pageSetupSubmit'
    ]),
    ...mapMutations(['subscription/setEditSetup']),
    dateToString(val) {
      if (!val) return
      return new Date(val).toJSON()
    },
    validationCompanyName(val) {
      const result = val ? true : false
      if (result) {
        this.errorMsg.companyName = ''
      } else {
        this.errorMsg.companyName = 'Please provide a valid company name.'
      }
      return result
    },
    validationMaxNumOfActiveTasks(val) {
      const result = val ? true : false
      if (result) {
        this.errorMsg.maxNumOfActiveTasks = ''
      } else {
        this.errorMsg.maxNumOfActiveTasks = 'Please provide a valid number.'
      }
      return result
    },
    validationValidFrom(val) {
      const result = val ? true : false
      if (result) {
        this.errorMsg.validFrom = ''
      } else {
        this.errorMsg.validFrom = 'Please choose date for from'
      }
      return result
    },
    validationValidUntil(val) {
      const result = val === null ? true : val ? true : false
      if (result) {
        this.errorMsg.validUntil = ''
      } else {
        this.errorMsg.validUntil = 'Please choose date for until'
      }
      return result
    },
    validationFsContacts() {
      // const result = val[0] ? true : false
      // if (result) {
      //   this.errorMsg.fsContacts = ''
      // } else {
      //   this.errorMsg.fsContacts = 'Please choose one'
      // }
      return true
    },
    validationForm() {
      let companyName = this.validationCompanyName(this.companyName)
      let maxNumOfActiveTasks = this.validationMaxNumOfActiveTasks(
        this.maxNumOfActiveTasks
      )
      let validFrom = this.validationValidFrom(this.validFrom)
      let validUntil = this.validationValidUntil(this.validUntil)
      let fsContacts = this.validationFsContacts(this.fsContacts)

      return (
        companyName &&
        maxNumOfActiveTasks &&
        validFrom &&
        validUntil &&
        fsContacts
      )
    },
    async SubmitHandler() {
      this.errorMsg.all = ''
      let validForm = this.validationForm()

      if (validForm) {
        let response = await this['subscription/pageSetupSubmit']({
          status: this.subscriptionType,
          id: this.$route.params.id
        })
        this.saveDone(response)
        return response
      } else {
        this.errorMsg.all = 'Please check form and try it again.'
      }
    },
    saveDone(status) {
      if (status === 'success') {
        this.$router.push('/platform/subscription')
      } else if (status === 'error') {
        this.errorMsg.all =
          'Please wait a minute and try it again. if not working. please reload this web!'
      }
    },
    modalShowHide() {
      this.cancelModalShow = !this.cancelModalShow
    },
    goBackHandler() {
      this.$router.push('/platform/subscription')
    }
  }
}
</script>
