<template>
  <div class="multiselect__tags multiselect__tags--customer">
    <div class="multiselect__tags-wrap" v-if="tagList">
      <span class="multiselect__tag" v-for="item of tagList" :key="item.id">
        <span>{{ item.fullName }}</span>
        <i
          aria-hidden="true"
          tabindex="1"
          class="multiselect__tag-icon"
          @mousedown.prevent="multiselectDeleteTag(item.emailAddress)"
        ></i>
      </span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'MultiSelectTag',
  props: {
    tagList: {
      type: Array,
      required: true
    }
  },
  methods: {
    multiselectDeleteTag(emailAddress) {
      let newList = this.tagList.filter(x => x.emailAddress !== emailAddress)
      this.$emit('update:tagList', newList)
    }
  }
}
</script>
